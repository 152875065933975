import React, { useState, useEffect } from "react";
import { getBooks } from "../contentful";
import marked from "marked";
import { Row, Col } from "react-bootstrap";
import TitleBar from "./TitleBar";
import { motion } from "framer-motion";

const Books = () => {
  const [books, setBooks] = useState([]);
  const [allBooks, setAllBooks] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getBooks().then((data) => {
      setBooks(data);
      setAllBooks(data);
    });
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const handleInput = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    const array = [...allBooks];
    if (e.target.value.length > 3) {
      searchItems();
    }
    if (e.target.value === "") {
      setBooks(array);
    }
  };

  const searchItems = () => {
    const newArray = [...allBooks];
    const filtered = newArray.filter((book) =>
      book.fields.title.toLowerCase().includes(searchTerm)
    );
    setBooks(filtered);
  };

  return (
    <div className='content'>
      <TitleBar title='2nd-Hand Sale' />

      <p>
        Here are some books which I am selling. They are all in good condition,
        have been very loved and valued, and they are looking for new owners who
        will enjoy them as much as me.
      </p>
      <p>
        If there is anything you would be interested in, please{" "}
        <a href='mailto:maumusika@gmail.com'> contact me. </a>
      </p>

      <input
        style={{ marginBottom: 25, width: "50%" }}
        onChange={handleInput}
        type='text'
        placeholder='start typing to search titles'
      />
      <Row>
        {books.map((book, index) => {
          let text;
          if (book.fields.description) {
            text = marked(book.fields.description);
          }

          return (
            <Col md={6} lg={4}>
              <motion.div
                initial={{ opacity: 0, rotateY: 180 }}
                animate={{ opacity: 1, rotateY: 0 }}
                transition={{ delay: index / 5 }}
                className='book-card'
              >
                <Row>
                  <Col>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h6>{book.fields.title}</h6>
                        {book.fields.sold && (
                          <p style={{ color: "red" }}>SOLD</p>
                        )}
                        <section dangerouslySetInnerHTML={{ __html: text }} />
                      </div>
                      {book.fields.price && (
                        <div className='price'>
                          {" "}
                          <p>€{book.fields.price}</p>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col>
                    {book.fields.cover && (
                      <img
                        src={book.fields.cover.fields.file.url}
                        style={{ height: "200px" }}
                      />
                    )}
                  </Col>
                </Row>
              </motion.div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Books;
