import React, { useState, useEffect } from "react";
import Translator from "./i18n/Translator";
import TitleBar from "./TitleBar";
import { getMusicTherapyPage, getPosts } from "../contentful";
import marked from "marked";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import PostCard from "./PostCard";

const MusicTherapyPage = () => {
  const [content, setContent] = useState([]);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getPosts().then((data) => setPosts(data));
    getMusicTherapyPage().then((data) => setContent(data));
  }, []);

  let switchedLang = window.localStorage.lang;

  return (
    <div>
      {content.map((content) => {
        let text;
        if (!switchedLang && content.fields.mainTextEn) {
          text = marked(content.fields.mainTextEn);
        } else if (!switchedLang && content.fields.mainTextEs) {
          text = marked(content.fields.mainTextEs);
        } else if (content.fields.mainTextEn && switchedLang === "en-US") {
          text = marked(content.fields.mainTextEn);
        } else if (content.fields.mainTextEu && switchedLang === "eu-EU") {
          text = marked(content.fields.mainTextEu);
        } else if (content.fields.mainTextEs && switchedLang === "es-ES") {
          text = marked(content.fields.mainTextEs);
        }

        return (
          <div className='page-contents'>
            <img
              style={{ width: "100%", maxHeight: "30vh", objectFit: "cover" }}
              src={content.fields.mainImage.fields.file.url}
              alt=''
            />
            <div className='content'>
              <TitleBar title={<Translator path='MusicTherapy' />} />
              <div
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          </div>
        );
      })}

      <div style={{ padding: "20px" }}>
        <Row>
          {posts.map((post) => {
            if (post.fields.musicTherapy)
              return (
                <Col md={4}>
                  <PostCard post={post} />
                </Col>
              );
          })}
        </Row>
      </div>
    </div>
  );
};

export default MusicTherapyPage;
