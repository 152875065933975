import React from "react";
import { Link } from "react-router-dom";
import loreak from '../assets/imgs/loreak1.png'
const Feature = (props) => {
  return (
    <div className="feature">
   <img style={{width:'150px'}} src={props.image} alt=""/>
        <p style={{textTransform:'uppercase'}} >{props.title}</p>
       
      </div>
   
    
     
  );
};

export default Feature;
