import React, { useEffect, useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaEnvelopeOpen,
  FaPhoneAlt,
} from "react-icons/fa";
import Translator from "./i18n/Translator";
import TitleBar from "./TitleBar";
import { Row, Col } from "react-bootstrap";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });


  return (
    <>
      <div >
        <TitleBar title={<Translator path="Contact" />} />

        <div className="contact-middle">
          <Row>
            <Col md={4}><div className="contact-card" >
          <a href="mailto:maumusika@gmail.com">
            <FaEnvelopeOpen size={40}/>
            <p>maumusika@gmail.com</p></a>
            </div></Col>
            <Col md={4}>  <div className="contact-card" >
          <a href="tel:0034680168425">
            <FaPhoneAlt size={40}/>
            <p>+34 680 168 425</p>
            </a>
            </div></Col>
            <Col md={4}>    <div className="contact-card" >
          <a href="https://www.instagram.com/mauartabe/" target="_blank">
            <FaInstagram size={40} />
           <p> Follow me on Instagram</p>
          </a>
          </div>
          </Col>

          
        
          </Row>
        </div>
      </div>
    </>
  );
};

export default Contact;
