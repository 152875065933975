import React, { useState, useEffect } from "react";
import TitleBar from "./TitleBar";
import { Link } from "react-router-dom";
import { getPosts, getTeachingPage } from "../contentful";
import { Row, Col } from "react-bootstrap";
import marked from "marked";
import PostCard from "./PostCard";
import Translator from "./i18n/Translator";

const Teaching = () => {
  const [posts, setPosts] = useState([]);
  const [text, setText] = useState([]);

  let switchedLang = window.localStorage.getItem("lang");

  useEffect(() => {
    getTeachingPage().then((data) => setText(data));
    getPosts().then((data) => setPosts(data));
  }, []);

  return (
    <div>
      {" "}
      {text.map((content) => {
        let text;
        if (!switchedLang) {
          text = marked(content.fields.textEn);
        } else if (content.fields.textEn && switchedLang === "en-US") {
          text = marked(content.fields.textEn);
        } else if (content.fields.textEu && switchedLang === "eu-EU") {
          text = marked(content.fields.textEu);
        } else if (content.fields.textEs && switchedLang === "es-ES") {
          text = marked(content.fields.textEs);
        }

        return (
          <div className='page-contents'>
            <img
              style={{ width: "100%", maxHeight: "30vh", objectFit: "cover" }}
              src={content.fields.mainImage.fields.file.url}
              alt=''
            />
            <div className='content'>
              <TitleBar title={<Translator path='Teaching' />} />
              <div
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </div>
          </div>
        );
      })}
      <Row>
        {posts.map((post) => {
          if (post.fields.teaching) {
            return (
              <Col md={4}>
                <PostCard post={post} />
              </Col>
            );
          }
        })}
      </Row>
    </div>
  );
};

export default Teaching;
