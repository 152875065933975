export default {
    translations: {
        
            About: 'About Me',
            Contact: 'Contact',
            MusicTherapy:"Music Therapy",
            ViolinViola: 'Violin & Viola',
            Teaching: 'Teaching',

            ReadMore: 'read more',
          }
          
    
}