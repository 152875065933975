import React from "react";
// JSX
import HeroSlider, { Slide, Nav, OverlayContainer } from "hero-slider";
import Translator from "./i18n/Translator";
import { Link } from "react-router-dom";

// Images

const slider = (props) => {
  return (
    <HeroSlider
      slidingAnimation='fade'
      orientation='horizontal'
      initialSlide={1}
      onBeforeChange={(previousSlide, nextSlide) =>
        console.log("onBeforeChange", previousSlide, nextSlide)
      }
      onChange={(nextSlide) => console.log("onChange", nextSlide)}
      onAfterChange={(nextSlide) => console.log("onAfterChange", nextSlide)}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.33)",
      }}
      settings={{
        slidingDuration: 450,
        slidingDelay: 0,
        shouldAutoplay: true,
        shouldDisplayButtons: false,
        autoplayDuration: 5000,
        height: "50vh",
      }}
    >
      <OverlayContainer>
        <div style={{ height: "100%" }}></div>
      </OverlayContainer>

      {props.images.map((image) => (
        <Slide
          background={{
            backgroundImage: image.fields.image.fields.file.url,
            // backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      ))}
      {/*
      <Slide
        background={{
          backgroundImage: image2,
          // backgroundAttachment: "fixed",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      />

      <Slide
        background={{
          backgroundImage: image3,
          // backgroundAttachment: "fixed",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      /> */}

      <Nav />
    </HeroSlider>
  );
};

export default slider;
