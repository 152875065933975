const client = require('contentful').createClient({
    space: 'gaokk1czky92',
    accessToken: '0AdwOOt7Nv_TRtNJ_xMcaEtVpzfTbdvq9ekgqbtHGMg'
  })
  var gt = new Date().toISOString();

  
  const getPosts = () => client.getEntries({content_type: 'post'}).then(response => response.items)
  const getSinglePost = (slug) => client.getEntries({content_type: 'post', 'sys.id': slug}).then(response => response.items)

  const getBiog = () => client.getEntries({content_type: 'biog'}).then(response => response.items)
  const getMusicianBiog = () => client.getEntries({content_type: 'musicianBiog'}).then(response => response.items)
  const getSliderImages = () => client.getEntries({content_type: 'sliderImage'}).then(response => response.items)
  const getBooks = () => client.getEntries({content_type: 'book', order:'fields.title'}).then(response => response.items)
  


 const getPrivacy = () => client.getEntries({content_type: 'privacy'}).then(response => response.items)
 const getHomepageText = () => client.getEntries({content_type: 'homepageText'}).then(response => response.items)
 const getMusicTherapyPage = () => client.getEntries({content_type: 'musicTherapyPage'}).then(response => response.items)
 const getTeachingPage = () => client.getEntries({content_type: 'teachingPage'}).then(response => response.items)

 




  export { getBooks, getTeachingPage, getPosts, getSinglePost, getMusicTherapyPage, getSliderImages, getMusicianBiog, getBiog, getHomepageText, }