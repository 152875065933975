import React  from "react";
import { Link } from "react-router-dom";
import {  FaFacebook, FaLinkedin, FaInstagram, FaTimesCircle } from "react-icons/fa";
import { Dropdown, NavLink, NavItem } from "react-bootstrap";
import Translator from "../i18n/Translator";
import I18n from "../i18n/i18n";
import { getProjects } from '../../contentful';

import "./SideDrawer.css";

const sideDrawer = (props) => {
  


  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <div>
      <nav className={drawerClasses}>
        <div className="close-cross">
      <FaTimesCircle id="cross" size={20} onClick={props.click} />
      </div>

        <div className="header">

          <hr></hr>
          <ul>
            <li>
              <Link onClick={props.click} to="/">
                <Translator path="Home" />
              </Link>
            </li>
            <li>
              <Link onClick={props.click} to="/biography">
                <Translator path="About" />
              </Link>
            </li>
            <li>
              <Link onClick={props.click} to="/teaching">
                <Translator path="Teaching" />
              </Link>
            </li>
            <li>
              <Link onClick={props.click} to="/musictherapy">
                <Translator path="MusicTherapy" />
              </Link>
            </li>
           

          <li>
          <Link onClick={props.click}  to="/performer"><Translator path="ViolinViola" /></Link>

          </li>
            
{/* 
            <li>
              <Link onClick={props.click} to="/contact">
                <Translator path="Contact" />
              </Link>
            </li> */}
          </ul>
          <div className="social">
            {/* <a
              href="https://www.facebook.com/ann.cnop.5"
              target="_blank"
            >
              <FaFacebook size={35} />
            </a> */}
            {/* <a
              href="https://www.linkedin.com/in/ann-cnop-501506105/"
              target="_blank"
            >
              <FaLinkedin size={35} />
            </a> */}
            <a
              href="https://www.instagram.com/mauartabe/"
              target="_blank"
            >
              <FaInstagram size={35} />
            </a>
          </div>
         
          <div className="i18n">
            <I18n />
          </div>
          

        </div>

      </nav>
    </div>
  );
};

export default sideDrawer;
