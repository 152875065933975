import React from 'react'
import { Row, Col } from 'react-bootstrap'
import {
    FaFacebook,
    FaInstagram,
    FaLinkedin,
    FaEnvelopeOpen,
    FaPhoneAlt,
  } from "react-icons/fa";

const ContactStrip = () => {

    return(
        <div className="contact-middle">
        <Row>
          <Col md={4}><div className="contact-card" >
        <a href="mailto:maumusika@gmail.com">
          <FaEnvelopeOpen size={20}/>
          <small> maumusika@gmail.com</small></a>
          </div></Col>
          <Col md={4}>  <div className="contact-card" >
        <a href="tel:0034680168425">
          <FaPhoneAlt size={20}/>
          <small> +34 680 168 425</small>
          </a>
          </div></Col>
          <Col md={4}>    <div className="contact-card" >
        <a href="https://www.instagram.com/mauartabe/" target="_blank">
          <FaInstagram size={20} />
         <small> Follow me on Instagram</small>
        </a>
        </div>
        </Col>

        
      
        </Row>
      </div>    )
}

export default ContactStrip;