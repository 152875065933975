export default {
    translations: {
        
        About: 'Sobre mi',
        MusicTherapy: 'Música Terapia',
        Teaching: 'Enseñanza de Música',
        ViolinViola: 'Violin & Viola',
        Contact: 'Contacto',

        ReadMore: 'leer más',

                
                }
          
    
}