import React, { useState, useEffect } from "react";
import Translator from "./i18n/Translator";
import { Row, Col } from "react-bootstrap";
import { getMusicianBiog, getPosts } from "../contentful";
import TitleBar from "./TitleBar";
import marked from "marked";
import { Link } from "react-router-dom";
import PostCard from "./PostCard";

const Performer = () => {
  const [text, setText] = useState([]);
  const [posts, setPosts] = useState([]);

  let switchedLang = window.localStorage.getItem("lang");

  useEffect(() => {
    getMusicianBiog().then((data) => setText(data));
    getPosts().then((data) => setPosts(data));
  }, []);

  return (
    <div>
      {text.map((info) => {
        let text;
        if (!switchedLang) {
          text = marked(info.fields.contentEn);
        } else if (switchedLang === "en-US") {
          text = marked(info.fields.contentEn);
        } else if (switchedLang === "eu-EU") {
          text = marked(info.fields.contentEu);
        } else if (switchedLang === "es-ES") {
          text = marked(info.fields.contentEs);
        }
        return (
          <div className='page-contents'>
            <img
              style={{ width: "100%" }}
              src={info.fields.image.fields.file.url}
              alt=''
            />
            <div>
              <TitleBar title={<Translator path='ViolinViola' />} />
              <div
                style={{ textAlign: "justify", padding: "20px" }}
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>
            </div>
          </div>
        );
      })}

      <div style={{ padding: "10px" }}>
        <Row>
          <Col md={6}>
            {posts.map((post) => {
              if (post.fields.music) {
                return <PostCard post={post} />;
              }
            })}
          </Col>
          <Col md={6}>
            {text.map((info) => {
              return (
                <div>
                  <Row>
                    {info.fields.cdCovers &&
                      info.fields.cdCovers.map((cd) => (
                        <Col xs={4}>
                          <div className='cd-wrapper'>
                            {" "}
                            <img
                              style={{ width: "100%" }}
                              src={cd.fields.file.url}
                            />
                          </div>
                        </Col>
                      ))}
                  </Row>
                </div>
              );
            })}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Performer;
