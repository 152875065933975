import React, { Component } from 'react';
import Main from './components/Main';
import Contact from './components/Contact';
import Footer from './components/Footer'
import Toolbar from './components/Toolbar/Toolbar'
import SideDrawer from './components/SideDrawer/SideDrawer'
import Backdrop from './components/Backdrop/Backdrop'
import SinglePost from './components/SinglePost'
import Biog from './components/Biog'
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Performer from './components/Performer';
import MusicTherapyPage from './components/MusicTherapyPage';
import Teaching from './components/Teaching';
import ContactStrip from './components/ContactStrip';
import Books from './components/Books';

class App extends Component {
  state ={
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false})
  }



  render(){
    let backdrop;

    if(this.state.sideDrawerOpen){
      backdrop = <Backdrop click={this.backdropClickHandler}/>
    }
  return (
    <Router>
  <div style={{height: '100%'}}>
      <Toolbar  drawerClickHandler={this.drawerToggleClickHandler} />
      <SideDrawer projects={this.state.projects} show={this.state.sideDrawerOpen} click={this.drawerToggleClickHandler}/>
      {backdrop}

      <main style={{marginTop:'156px'}}>
      </main>    
      <Switch>
    <Route exact path="/" component={Main}/>
    <Route path="/contact" component={Contact}/>
    <Route path="/performer" component={Performer}/>
    <Route path="/posts/:id" component={SinglePost}/>
    <Route path="/biography" component={Biog}/>
    <Route path="/musictherapy" component={MusicTherapyPage}/>
    <Route path="/teaching" component={Teaching}/>
    <Route path="/books" component={Books}/>


    </Switch>
    <ContactStrip />
    <Footer/>

    </div>
    </Router>
  );
}
}

export default App;
