import React from "react";
import { FaLinkedin, FaInstagram, FaFacebook } from "react-icons/fa";
import I18n from "../i18n/i18n";
import { Link } from "react-router-dom";
import logo from "../../assets/imgs/logo.png";

import "./Toolbar.css";
import DrawerToggleButton from "../DrawerToggleButton/DrawerToggleButton";

const toolbar = (props) => (
  <header className="toolbar">
    <nav className="toolbar__navigation">
      <div className="toolbar__toggle-button">
        <DrawerToggleButton click={props.drawerClickHandler} />
      </div>
      <div className="toolbar__logo">
        <Link to="/">
        <img className="logo" src={logo} alt="" />
        </Link>
      </div>
      <div className="toolbar_navigation-items">
        <div className="nav-right">
          <I18n />

          <a href="https://www.instagram.com/mauartabe/" target="_blank">
            <FaInstagram color={"white"} size={20} />
          </a>
        </div>
      </div>
    </nav>
  </header>
);

export default toolbar;
