import React, { useState, useEffect } from "react";
import Translator from "./i18n/Translator";
import { Row, Col } from "react-bootstrap";
import { getBiog } from "../contentful";
import TitleBar from "./TitleBar";
import marked from "marked";

const Biog = () => {
  const [text, setText] = useState([]);
  var switchedLang = window.localStorage.getItem("lang");

  useEffect(() => {
    window.scrollTo(0, 0);
    getBiog().then((data) => setText(data));
  }, []);

  return (
    <div className='page-contents'>
      {text.map((info) => {
        let text;
        if (!switchedLang) {
          text = marked(info.fields.contentEn);
        } else if (switchedLang === "en-US") {
          text = marked(info.fields.contentEn);
        } else if (switchedLang === "eu-EU") {
          text = marked(info.fields.contentEu);
        } else if (switchedLang === "es-ES") {
          text = marked(info.fields.contentEs);
        }
        return (
          <div>
            <img
              style={{ width: "100%" }}
              src={info.fields.image.fields.file.url}
              alt=''
            />

            <div className='content'>
              <TitleBar title={<Translator path='About' />} />

              <div
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Biog;
