
// Lastly, we import everything and export a single object

import enUsTranslations from './en-us'
import euTranslations from './eu-EU'
import esTranslations from './es-ES'

export default {
  'en-US': enUsTranslations,
  'eu-EU': euTranslations,
  'es-ES': esTranslations
}