export default {
  translations: {
      

    About: 'Nitaz',
    MusicTherapy: 'Musikoterapia',
    Teaching: 'Irakaskuntza',
    Contact: 'Kontaktua',
    ViolinViola: 'Biolin & Biola',

    ReadMore: 'gehiago',


  
  }
        
  
}