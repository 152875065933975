import React, { Component } from "react";
import "../styles/stylesheet.css";
import { Row, Col } from "react-bootstrap";
import { getSliderImages } from '../contentful'
import { Link } from "react-router-dom";
import Slider from "./Slider";
import Feature from "./Feature";
import Translator from "./i18n/Translator";

import lor1 from '../assets/imgs/loreak1.png'
import lor2 from '../assets/imgs/loreak2.png'
import lor3 from '../assets/imgs/loreak3.png'

class Main extends Component {
  state = {
  
    sliderImages: [],
  };



  componentDidMount() {
       getSliderImages().then((data) => this.setState({ sliderImages: data }));
  }

  render() {
    return (
      <div>
        <Slider images={this.state.sliderImages} />
        <div >
         
          <Row>
            <Col md={4}>
              <div className="home-column">
              <Link className="link" to="/teaching">

                <Feature
                  title={<Translator path="Teaching" />}
                  image={lor1}

                />
            </Link>
              </div>
            </Col>

            <Col md={4}>
              {" "}
              <div className="home-column">
                {" "}
                <Link className="link" to="/performer">
                <Feature
                  title={<Translator path="ViolinViola" />}
                  image={lor2}

                />
                </Link>
             
              </div>
            </Col>

            <Col md={4}>
              <div className="home-column">
              <Link className="link" to="/musictherapy">

                <Feature
                  title={<Translator path="MusicTherapy" />}
              image={lor3}
                />
             </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Main;
