import React from 'react'
import marked from 'marked';
import { Link } from 'react-router-dom'
import { Translation } from 'react-i18next';
import Translator from './i18n/Translator';
const PostCard = (props) => {

        let video = null;
        if (props.post.fields.youTubeLink) {
          video = marked(props.post.fields.youTubeLink);
        } 
        if (props.post.fields.mainImage){video = null}

    return (
        <div className="home-column-post">
       {props.post.fields.title && <p>{props.post.fields.title}</p>}
       
       <section dangerouslySetInnerHTML={{ __html: video }} />
        
        
        {props.post.fields.mainImage && (
          <img
            style={{ width: "100%" }}
            src={props.post.fields.mainImage.fields.file.url}
          />
        )}

        <Link className="button" to={`/posts/${props.post.sys.id}`} ><Translator path="ReadMore" /></Link>

      </div>
    )
}

export default PostCard;